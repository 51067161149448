import TestimonialSwiper from "components/TestimonialsSwiper";
import React from "react";

const data = [
  {
    content:
      " This business intelligence reporting platform saved us over 80 hours of manual reporting and the solution immediately exposed us to data that allowed us to make quick decisions that positively affected our bottom line.",
    title: "CFO",
  },
  {
    content:
      "This business intelligence allowed us to immediately obtain accurate data, increase our profitability and get a better understanding of our customers and what they purchase.",
    title: "National Sales Manager",
  },
  {
    content:
      "Interning has given me the opportunity to see the results of my work being utilized in real life by a real client. Working here has given me the opportunity to learn more about myself, my interests and disinterest because of the opportunities and closeness of the company.",
    name: "Kou V.",
    title: "Manager Implementations",
  },
  {
    content:
      " With thousands of fast-moving SKUs we are able to manage the forecasting and planning in literally minutes with customized reports.		",
    name: "Pranav Gupta",
    title: "Forecast Analyst",
  },
  {
    content:
      "I remember meeting Keith and Travis back in 2014 when I gave them the Governor’s Award for Outstanding Employer, and the company’s growth since then has been amazing. I’ve seen them save NC companies and companies across the country millions. And everybody wins . . . it is a no-brainer — the savings they deliver save jobs!",
    title: "74th Governor of North Carolina",
    name: "Pat McCrory",
  },
];
const SuccessStories = () => {
  return (
    <section className="flex gap-5 justify-between self-center mt-48 w-full bg-stone-900 rounded-[50px] max-md:flex-wrap max-md:mt-10">
      <div className="flex justify-center w-full md:w-[45%]  px-16 py-12   text-white bg-amber-500 rounded-[50px] max-md:px-5">
        <div className="flex flex-col mt-10 md:mt-32 max-w-full w-[344px]">
          <h2 className="text-5xl font-bold max-md:text-4xl">
            Our Success Stories
          </h2>
          <p className="mt-14 text-lg leading-8 max-md:mt-10">
            Our clients have transformed their shipping operations, discovered
            hidden savings, and improved their profit margins.{" "}
            <span className="font-bold text-white">
              Join thousands of businesses that trust TransImpact.
            </span>
          </p>
        </div>
      </div>

      <TestimonialSwiper data={data} />
    </section>
  );
};

export default SuccessStories;
