import React from "react";

const Footer = () => {
  return (
    <footer className="flex flex-col mt-32 w-full max-md:mt-10 max-md:max-w-full">
      <div className="w-full border border-solid bg-neutral-300 border-neutral-300 min-h-[1px] max-md:max-w-full" />
      <div className="flex flex-col gap-5 md:gap-20  justify-center items-center self-center px-5 mt-24 w-full text-2xl font-bold text-black max-w-[1251px] md:flex-row max-md:mt-10 max-md:max-w-full">
        <h2 className=" my-auto text-5xl max-md:text-4xl">Contact Us</h2>

        <div className="flex flex-col xl:flex-row xl:gap-20">
          <a
            href="tel:+12163935933"
            className="self-stretch my-auto hover:text-amber-500"
          >
            (+1) 216-393-5933
          </a>
          <a
            href="mailto:info@avercast.com"
            className="self-stretch my-auto hover:text-amber-500"
          >
            info@avercast.com
          </a>
        </div>
        <div className="flex flex-row gap-3">
          <a
            href="https://www.facebook.com/TeamTransImpact"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
            >
              <circle cx="23" cy="23" r="23" fill="#FF9900" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21 34V23.6471H17V19.7647H21V17.6799C21 13.7328 22.9862 12 26.2333 12C27.7883 12 28.6106 12.1165 29 12.1695V15.8824H26.7853C25.4071 15.8824 25 16.6174 25 18.1056V19.7647H28.9654L28.4172 23.6471H25V34H21Z"
                fill="white"
              />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/TeamTransImpact/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
            >
              <circle cx="23" cy="23" r="23" fill="#FF9900" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19 13H27C30.3137 13 33 15.7638 33 19.1731V27.4038C33 30.8131 30.3137 33.5769 27 33.5769H19C15.6863 33.5769 13 30.8131 13 27.4038V19.1731C13 15.7638 15.6863 13 19 13ZM29 16.0869C29.5 16.0869 30 16.6013 30 17.1158C30 17.6302 29.5 18.1446 29 18.1446C28.5 18.1446 28 17.6302 28 17.1158C28 16.6013 28.5 16.0869 29 16.0869ZM28 23.2888C28 20.4517 25.7575 18.1445 23 18.1445C20.2425 18.1445 18 20.4517 18 23.2888C18 26.1258 20.2425 28.433 23 28.433C25.7575 28.433 28 26.1258 28 23.2888ZM23 20.2019C21.3431 20.2019 20 21.5838 20 23.2885C20 24.9931 21.3431 26.375 23 26.375C24.6569 26.375 26 24.9931 26 23.2885C26 21.5838 24.6569 20.2019 23 20.2019Z"
                fill="white"
              />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/avercast-llc"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
            >
              <circle cx="23" cy="23" r="23" fill="#FF9900" />
              <path
                d="M16.7905 32.8632H12.4921V18.6465H16.7905V32.8632Z"
                fill="white"
              />
              <path
                d="M14.5062 17.1508C13.1205 17.1508 12 15.996 12 14.5746C12 13.1533 13.122 12 14.5062 12C15.8875 12 17.0109 13.1548 17.0109 14.5746C17.0109 15.996 15.8875 17.1508 14.5062 17.1508Z"
                fill="white"
              />
              <path
                d="M33.1561 32.8632H28.8606V25.9495C28.8606 24.3005 28.8315 22.1797 26.6269 22.1797C24.3903 22.1797 24.0464 23.9755 24.0464 25.8297V32.8632H19.7509V18.6464H23.8745V20.589H23.9327C24.5068 19.4717 25.9086 18.293 27.9995 18.293C32.3518 18.293 33.1561 21.2376 33.1561 25.0658V32.8632Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="mt-24 w-full border border-solid bg-neutral-300 border-neutral-300 min-h-[1px] max-md:mt-10 max-md:max-w-full" />
      <div className="flex gap-5 self-center px-5 mt-9 w-full text-sm leading-7 text-center max-w-[1270px] text-neutral-700 max-md:flex-wrap max-md:max-w-full">
        <address className="flex-auto not-italic">
          4500 Rockside Rd. Suite 210, Independence, OH 44131
        </address>
        <p className="flex-auto">
          Copyright ©2024 Avercast, LLC. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
