import { Swiper, SwiperSlide } from "swiper/react";

import { useRef } from "react";
import "swiper/css";

import ServiceCard from "./home/ServiceCard";
import SolutionCard from "./avercast/SolutionCard";

const breakpoints = {
  320: {
    slidesPerView: 1,
    spaceBetween: 4,
  },
  480: {
    slidesPerView: 1,
    spaceBetween: 2,
  },
  640: {
    slidesPerView: 2,
    spaceBetween: 2,
  },
  768: {
    slidesPerView: 2,
    spaceBetween: 4,
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 2,
  },
};

const CustomSwiper = ({ title, data, service = true }) => {
  const swiperRef = useRef();

  return (
    <div className="mt-20 max-md:mt-10  max-md:max-w-full">
      <div className="flex justify-between items-center">
        <h2 className="text-5xl text-center md:text-start font-bold text-black max-md:max-w-full max-md:text-4xl">
          {title}
        </h2>
        <div className="flex  lg:hidden gap-10">
          <svg
            onClick={() => swiperRef.current.slidePrev()}
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="16"
            viewBox="0 0 27 16"
            className="transform rotate-180 cursor-pointer"
            fill="none"
          >
            <path
              d="M26.449 8.70711C26.8396 8.31658 26.8396 7.68342 26.449 7.29289L20.0851 0.928932C19.6946 0.538408 19.0614 0.538408 18.6709 0.928932C18.2803 1.31946 18.2803 1.95262 18.6709 2.34315L24.3277 8L18.6709 13.6569C18.2803 14.0474 18.2803 14.6805 18.6709 15.0711C19.0614 15.4616 19.6946 15.4616 20.0851 15.0711L26.449 8.70711ZM0 9H25.7419V7H0V9Z"
              fill="black"
            />
          </svg>
          <svg
            onClick={() => swiperRef.current.slideNext()}
            className="cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="16"
            viewBox="0 0 27 16"
            fill="none"
          >
            <path
              d="M26.449 8.70711C26.8396 8.31658 26.8396 7.68342 26.449 7.29289L20.0851 0.928932C19.6946 0.538408 19.0614 0.538408 18.6709 0.928932C18.2803 1.31946 18.2803 1.95262 18.6709 2.34315L24.3277 8L18.6709 13.6569C18.2803 14.0474 18.2803 14.6805 18.6709 15.0711C19.0614 15.4616 19.6946 15.4616 20.0851 15.0711L26.449 8.70711ZM0 9H25.7419V7H0V9Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <Swiper
        breakpoints={breakpoints}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        {service
          ? data.map((service, index) => (
              <SwiperSlide key={index} className="pt-16">
                <ServiceCard {...service}></ServiceCard>
              </SwiperSlide>
            ))
          : data.map((service, index) => (
              <SwiperSlide key={index} className="pt-16">
                <SolutionCard {...service}></SolutionCard>
              </SwiperSlide>
            ))}
      </Swiper>
    </div>
  );
};

export default CustomSwiper;
