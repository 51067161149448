import React from "react";
import Logo from "./Logo";
import CallToAction from "./CallToAction";

const Header = () => {
  return (
    <header className="flex flex-col items-center pb-8 bg-white">
      <div className="flex flex-col md:px-5 w-full max-w-[1239px] max-md:max-w-full">
        <div className="max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <a href="/" className="flex flex-col mt-4 text-lg font-bold  max-md:max-w-full">
                <Logo />
                <div className="hidden md:block">
                  <h1 className="mt-36 text-5xl text-black max-md:mt-10 max-md:max-w-full max-md:text-4xl text-center md:text-left">
                    Achieve Greater Visibility and Boost Your Margins with
                    TransImpact
                  </h1>
                  <p className="mt-10 leading-8 text-neutral-700 max-md:max-w-full text-center md:text-left">
                    Our Parcel Spend Management technology saves on shipping and
                    improves your supply chain efficiency.
                  </p>
                  <CallToAction link={"https://app.hubspot.com/meetings/charity-araoz?uuid=157bfaeb-0be4-4e59-9252-7e28423e092e"} />
                </div>
              </a>
            </div>
            <div className="flex  relative flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="grow  max-md:max-w-full">
                <div className="flex gap-4 max-md:flex-col max-md:gap-0 mx-auto w-full  overflow-clip">
                  <img
                    loading="lazy"
                    src={require("assets/home/hero/deer.png")}
                    alt="Deer"
                    className="w-11/12 md:w-full h-auto absolute top-[15%] right-[10%]  md:top-[40%] md:-left-[50%]"
                  />

                  <div className="flex flex-col  w-6/12 max-md:ml-0 max-md:w-full">
                    <div className="flex gap-4 justify-center md:justify-start flex-row md:flex-col grow mt-5   md:mt-16">
                      <img
                        loading="lazy"
                        src={require("assets/home/hero/1.png")}
                        alt="hero1"
                        className="w-[169px] md:w-full aspect-[1.02]"
                      />
                      <img
                        loading="lazy"
                        src={require("assets/home/hero/2.png")}
                        alt="hero2"
                        className="w-[169px] md:w-full  aspect-[1.02]"
                      />
                      <img
                        loading="lazy"
                        src={require("assets/home/hero/3.png")}
                        alt="hero3"
                        className="w-[169px] md:w-full aspect-[1.02]"
                      />
                    </div>
                  </div>
                  <div className="w-6/12 max-md:ml-0 max-md:w-full">
                    <div className="flex gap-4 md:flex-col max-md:mt-4 justify-center md:justify-start">
                      <img
                        loading="lazy"
                        src={require("assets/home/hero/4.png")}
                        alt="hero4"
                        className="ml-3  w-[169px] md:w-full md:ml-0 aspect-[1.32]"
                      />
                      <img
                        loading="lazy"
                        src={require("assets/home/hero/5.png")}
                        alt="hero5"
                        className="w-[169px] md:w-full aspect-[1.02]"
                      />
                      <img
                        loading="lazy"
                        src={require("assets/home/hero/6.png")}
                        alt="hero6"
                        className="w-[169px] md:w-full  aspect-[1.02]"
                      />
                    </div>
                  </div>
                </div>
                <div className="block md:hidden px-[7%]">
                  <h1 className="mt-36 text-5xl  text-black max-md:mt-10 max-md:max-w-full max-md:text-4xl text-center md:text-left">
                    Achieve Greater Visibility and Boost Your Margins with
                    TransImpact
                  </h1>
                  <p className="mt-10 leading-8 text-neutral-700 max-md:max-w-full text-center md:text-left">
                    Our Parcel Spend Management technology saves on shipping and
                    improves your supply chain efficiency.
                  </p>
                  <CallToAction link={"https://app.hubspot.com/meetings/charity-araoz?uuid=157bfaeb-0be4-4e59-9252-7e28423e092e"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
