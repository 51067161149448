
import React from "react";

const Logo = () => {
  return (
    <img
      loading="lazy"
      src={require("assets/home/transimpact-logo.png")}
      alt="TransImpact Logo"
      className="max-w-full aspect-[3.33] w-[193px] mx-auto md:mx-0"
    />
  );
};

export default Logo;
