import { useEffect } from "react";
import Footer from "components/avercast/Footer";
import Header from "components/avercast/Header";
import Solutions from "components/avercast/Solutions";
import SuccessStories from "components/avercast/SuccessStories";
import UnlockPotential from "components/avercast/UnlockPotential";
import WhyChooseUs from "components/avercast/WhyChooseUs";
import ExperienceAvercast from "components/avercast/ExperienceAvercast";


const Avercast = () => {
    useEffect(() => {
        document.title = 'Avercast';
    }, []);
    return (
        <main className="flex flex-col pb-8 bg-white">
            <Header />
            <WhyChooseUs />
            <UnlockPotential />
            <Solutions />
            <SuccessStories />
            <ExperienceAvercast />
            <Footer />
        </main>
    );
}

export default Avercast;