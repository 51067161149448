import React from "react";

const CallToAction = ({ link }) => {
  return (
    <div className="flex  flex-col md:flex-row gap-5 items-center mt-12 max-md:mt-10 max-sm:self-center mx-auto md:mx-0 ">
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="justify-center"
      >
        <button className="justify-center  whitespace-nowrap max-w-fit items-center px-5 py-4 w-full text-white bg-amber-500 rounded-md hover:text-amber-500 border border-amber-500 hover:bg-white">
          Schedule a Demo
        </button>
      </a>
      {/* <button className="flex justify-center max-w-40 my-auto  text-stone-900 text-nowrap hover:text-amber-500">
        <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
          Scheduel a Demo
        </a>
      </button> */}
    </div>
  );
};
export default CallToAction;
