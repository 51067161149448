import Avercast from "pages/Avercast";
import Home from "pages/Home";
import { Routes, Route } from "react-router-dom";

const RouteList = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/avercast" element={<Avercast />} />
    </Routes>
  );
};

export default RouteList;
