import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useRef } from "react";

const TestimonialSwiper = ({ data }) => {
  const swiperRef = useRef();

  return (
    <>
      <Swiper
        slidesPerView={1}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        {data.map((test, index) => {
          return (
            <SwiperSlide key={index}>
              <TestimonialContent {...test} />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className="flex z-10 md:flex-col gap-8 justify-center items-center w-full py-7 md:w-24 md:px-11 md:py-20 bg-neutral-800 rounded-[50px] max-md:px-5 ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="16"
          viewBox="0 0 27 16"
          className="transform rotate-180 cursor-pointer"
          onClick={() => swiperRef.current.slidePrev()}
          fill="none"
        >
          <path
            d="M26.449 8.70711C26.8396 8.31658 26.8396 7.68342 26.449 7.29289L20.0851 0.928932C19.6946 0.538408 19.0614 0.538408 18.6709 0.928932C18.2803 1.31946 18.2803 1.95262 18.6709 2.34315L24.3277 8L18.6709 13.6569C18.2803 14.0474 18.2803 14.6805 18.6709 15.0711C19.0614 15.4616 19.6946 15.4616 20.0851 15.0711L26.449 8.70711ZM0 9H25.7419V7H0V9Z"
            fill="white"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="16"
          viewBox="0 0 27 16"
          className="cursor-pointer"
          onClick={() => swiperRef.current.slideNext()}
          fill="none"
        >
          <path
            d="M26.449 8.70711C26.8396 8.31658 26.8396 7.68342 26.449 7.29289L20.0851 0.928932C19.6946 0.538408 19.0614 0.538408 18.6709 0.928932C18.2803 1.31946 18.2803 1.95262 18.6709 2.34315L24.3277 8L18.6709 13.6569C18.2803 14.0474 18.2803 14.6805 18.6709 15.0711C19.0614 15.4616 19.6946 15.4616 20.0851 15.0711L26.449 8.70711ZM0 9H25.7419V7H0V9Z"
            fill="white"
          />
        </svg>
      </div>
    </>
  );
};

export const TestimonialContent = ({ name, content, title }) => {
  return (
    <div className="flex  mx-auto w-11/12 items-center flex-col my-auto text-lg leading-8 text-white max-w-[500px] p-5 pointer-events-none md:mt-40">
      <p className="text-neutral-200  text-center mb-5">{content}</p>

      <p className="flex items-center flex-col md:flex-row gap-5">
        {name && <span className="font-bold">{name}</span>}
        {title && <span>{title}</span>}{" "}
      </p>
    </div>
  );
};

export default TestimonialSwiper;
