import React from "react";

const ServiceCard = ({ icon, title, features }) => {
  return (
    <div className="flex relative flex-col  min-w-80 max-w-[500px] min-h-fit  px-8 pt-8 py-4  max-md:ml-0 rounded-md border border-gray-200 pointer-events-none">
      <div className="flex flex-col  grow text-lg leading-8 text-neutral-700 ">
        <div className="absolute z-50 top-[-9%]  left-7">{icon}</div>
        <div className="my-4">
          <p className="font-bold   mb-5 text-black">{title}</p>
          {features.map((feature, index) => (
            <React.Fragment key={index}>
              <p>
                <span className="text-base mb- font-bold">{feature.title}</span>
                <span className="text-base"> {feature.description}</span>
              </p>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
