import React from "react";

const SolutionCard = ({ icon, title, features }) => {
  return (
    <div className="flex  relative rounded-md border border-gray-300  min-w-80   px-8 pt-8 py-4  flex-col max-md:ml-0  max-w-[500px] pointer-events-none">
      <div className="flex flex-col grow text-lg leading-8 text-neutral-700 ">
        <div className="absolute top-[-8%] left-6">{icon}</div>
        <div className="mt-8">
          <p className="font-bold text-black">{title}</p>
          <br />
          {features.map((feature, index) => (
            <React.Fragment key={index}>
              <p className="mb-5">
                <span className="text-base font-bold">{feature.title}</span>
                <span className="text-base"> {feature.description}</span>
              </p>
            </React.Fragment>
          ))}
        </div>
        {/* <a
          href="https://www.facebook.com"
          target="_blank"
          rel="noreferrer"
          className="flex absolute bottom-2 gap-5 text-base font-bold leading-8 text-black justify-between h-7 mt-auto items-center"
        >
          <div>See more</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="16"
            viewBox="0 0 28 16"
            fill="none"
          >
            <path
              d="M27.3522 8.70711C27.7428 8.31658 27.7428 7.68342 27.3522 7.29289L20.9883 0.928932C20.5978 0.538408 19.9646 0.538408 19.5741 0.928932C19.1835 1.31946 19.1835 1.95262 19.5741 2.34315L25.2309 8L19.5741 13.6569C19.1835 14.0474 19.1835 14.6805 19.5741 15.0711C19.9646 15.4616 20.5978 15.4616 20.9883 15.0711L27.3522 8.70711ZM0.903198 9H26.6451V7H0.903198V9Z"
              fill="black"
            />
          </svg>
        </a> */}
      </div>
    </div>
  );
};

export default SolutionCard;
