
import React from "react";

const WhyChooseUs = () => {
  return (
    <section className="flex flex-col items-center mt-52 max-md:mt-10 max-w-[1100px] mx-auto">
      <h2 className="text-lg font-bold text-amber-500">why choose us</h2>
      <h3 className="mt-8 text-5xl font-bold text-center text-black max-md:max-w-full max-md:text-4xl">
        Why TransImpact?
      </h3>
      <p className="mt-12 text-lg leading-8 text-center text-neutral-700 max-md:mt-10 max-md:max-w-full">
        TransImpact is a leader in supply chain technologies. Our Parcel Spend
        Management and Supply Chain Planning technologies have helped businesses
        save over $1 billion in shipping costs, making them more profitable and
        efficient.
      </p>
    </section>
  );
};

export default WhyChooseUs;
