import Footer from "components/Footer";
import ContactInfo from "components/home/ContactInfo";
import Header from "components/home/Header";
import ImmediateSavings from "components/home/ImmediateSavings";
import Services from "components/home/Services";
import SuccessStories from "components/home/SuccessStories";
import WhyChooseUs from "components/home/WhyChooseUs";
import { useEffect } from "react";

const HomePage = () => {
  useEffect(() => {
    document.title = "HomePage";
  }, []);
  return (
    <div className="w-full ">
      <Header />
      <div className="px-[7%]">
        <WhyChooseUs />
        <ImmediateSavings />
        <Services />
        <SuccessStories />
        <ContactInfo />
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
