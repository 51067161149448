import { ReactComponent as AltLogo } from "assets/alt-logo.svg";
import SocialLogo from "./SocialLogo";

const Footer = () => {
    return (
        <div className="h-fit w-full">
            <div className="w-full h-[1px] bg-black bg-opacity-10 mt-10"></div>
            <div className="text-[#3D3D3D] text-center w-full my-10">
                Copyright © {new Date().getFullYear()} Transimpact. All rights reserved.
            </div>
        </div>
    );
}

export default Footer;