import CallToAction from "components/home/CallToAction";
import React from "react";

const ExperienceAvercast = () => {
  return (
    <section className="md:flex   hide-bg lg:bg-img py-40 lg:py-60  flex-col mx-auto  mt-28 w-full max-w-[1800px] max-md:mt-10 max-md:max-w-full">
      <img
        className="lg:hidden object-cover  mb-2 inset-0 size-full"
        src={require("assets/avercast/experience-mob-bg.png")}
        alt="experience-bg"
      ></img>
      <div className="mx-auto  mt-0 max-w-full w-[892px] max-md:mt-0">
        <div className="flex justify-center  gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[70%] max-md:ml-0 max-md:w-full">
            <div className="flex relative flex-col px-5 text-center max-md:max-w-full">
              <h2 className="self-center  text-5xl font-bold text-black max-md:max-w-full max-md:text-4xl">
                Experience the Avercast Advantage
              </h2>
              <p className="mt-12 text-lg leading-8 text-neutral-700 max-md:mt-10 max-md:max-w-full">
                <span className="font-bold">
                  Ready to optimize your supply chain and drive bottom-line
                  growth?
                </span>{" "}
                Schedule a free demo today and see how Avercast can help you
                achieve your goals.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=" mt-7  self-center max-w-full w-[612px]">
        <div className="flex items justify-center gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex  flex-col w-[64%] items-center justify-center max-md:ml-0 max-md:w-full">
            <CallToAction link={"https://meetings.hubspot.com/jake-daly/demo"} />
          </div>
        </div>
      </div>

      <img
        className="lg:hidden object-cover  mb-2 inset-0 size-full"
        src={require("assets/avercast/deer-leg.png")}
        alt="experience-bg"
      ></img>
    </section>
  );
};

export default ExperienceAvercast;
