import React from "react";

const WhyChooseUs = () => {
  return (
    <section className="flex flex-col self-center  mt-16 w-full max-w-[1213px] max-md:mt-10 max-md:max-w-full px-[10%]">
      <h2 className="self-center text-lg font-bold text-amber-500">
        why choose us
      </h2>
      <h3 className="self-center mt-8 text-5xl font-bold text-center text-black max-md:max-w-full max-md:text-4xl">
        Why Choose Avercast?
      </h3>
      <p className="self-center mt-12 text-lg leading-8 text-center text-neutral-700 max-md:mt-10 max-md:max-w-full">
        Avercast is a leading supply chain planning and business intelligence
        technology. We help businesses of all sizes optimize their operations
        and reduce costs with our powerful, easy-to-use software.
      </p>
    </section>
  );
};

export default WhyChooseUs;
