import React from "react";
import CallToAction from "./CallToAction";

const ImmediateSavings = () => {
  return (
    <section className="self-center mt-60 w-full max-w-[1100px] max-md:mt-10 max-md:max-w-full mx-auto">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[37%] max-md:ml-0 max-md:w-full">
          <div className=" hidden md:flex md:flex-col self-stretch my-auto text-lg font-bold max-md:mt-10">
            <h2 className="text-5xl text-black max-md:text-4xl">
              Immediate Savings
            </h2>
            <p className="mt-12 leading-8 text-neutral-700 max-md:mt-10">
              Our tools and expertise provide practical, value-driven solutions
              that transform supply chain operations. With over 800 years of
              combined industry experience, we deliver results that matter.
            </p>
            <CallToAction link={"https://app.hubspot.com/meetings/charity-araoz?uuid=157bfaeb-0be4-4e59-9252-7e28423e092e"} />
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[63%] max-md:ml-0 max-md:w-full">
          <div className="grow max-md:mt-10 max-md:max-w-full">
            <div className="flex  relative  gap-2">
              <img
                loading="lazy"
                src={require("assets/home/hero/deer.png")}
                alt=""
                className=" w-4/5 md:w-3/6 h-auto absolute bottom-[28%] left-[25%]  md:bottom-[28%] md:left-[50%]"
              />
              <div className="flex flex-col w-2/5 max-md:ml-0 max-md:w-full">
                <div className="flex  gap-y-2 flex-col max-md:mt-4">
                  <img
                    loading="lazy"
                    src={require("assets/home/immediate-savings/1.png")}
                    alt=""
                    className="w-full aspect-[1.32]"
                  />
                  <img
                    loading="lazy"
                    src={require("assets/home/immediate-savings/2.png")}
                    alt=""
                    className="w-full aspect-[.90]"
                  />
                </div>
              </div>
              <div className="flex flex-col mt-14 gap-y-2 ml-5 w-2/5 max-md:ml-0 max-md:w-full">
                <img
                  loading="lazy"
                  src={require("assets/home/immediate-savings/3.png")}
                  alt=""
                  className="w-full rounded-xl aspect-[.90]"
                />
                <img
                  loading="lazy"
                  src={require("assets/home/immediate-savings/4.png")}
                  alt=""
                  className="w-full rounded-xl aspect-[1.32]"
                />
              </div>
            </div>
            <div className=" flex flex-col md:hidden text-center my-auto text-lg font-bold max-md:mt-10">
              <h2 className="text-5xl text-black max-md:text-4xl">
                Immediate Savings
              </h2>
              <p className="mt-12 leading-8 text-neutral-700 max-md:mt-10">
                Our tools and expertise provide practical, value-driven
                solutions that transform supply chain operations. With over 800
                years of combined industry experience, we deliver results that
                matter.
              </p>
              <CallToAction link={"https://app.hubspot.com/meetings/charity-araoz?uuid=157bfaeb-0be4-4e59-9252-7e28423e092e"} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImmediateSavings;
