import CallToAction from "components/home/CallToAction";
import React from "react";

const UnlockPotential = () => {
  return (
    <div className="self-end mt-48 max-md:mt-10 max-md:max-w-full px-[10%] min-[1600px]:px-0 text-center lg:text-left max-w-[1500px] mx-auto">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="hidden md:flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <div className=" flex-col self-stretch my-auto text-lg font-bold max-md:mt-10 max-md:max-w-full">
            <h2 className="text-5xl text-black max-md:max-w-full max-md:text-4xl">
              Unlock Your Supply Chain's Potential
            </h2>
            <p className="mt-14 leading-8 text-neutral-700 max-md:mt-10 max-md:max-w-full">
              Our solutions are tailored to meet your specific needs, providing
              practical, value-driven tools that enhance your supply chain
              performance. With over 280 advanced algorithms and machine
              learning technology, our software ensures accurate forecasts and
              efficient planning.
            </p>
            <div className="flex flex-col mx-auto lg:mx-0 lg:flex-row gap-5 self-start mt-16 max-md:mt-10">
              <a href="https://meetings.hubspot.com/jake-daly/demo" target="_blank" rel="noreferrer" className="justify-center px-5 py-4 text-white bg-amber-500 rounded-md border border-amber-500 hover:bg-transparent hover:text-amber-500">
                Schedule a Demo
              </a>
              {/* <button className="flex-auto my-auto text-stone-900 hover:text-amber-500">
                Schedule a Demo
              </button> */}
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            src={require("assets/avercast/book-call.png")}
            alt="Supply Chain Visualization"
            className=" mt-10 lg:mt-0 object-contain max-h-[40vh] lg:max-h-[500px] w-fit mx-auto md:ml-auto"
          />
        </div>
        <div className="flex md:hidden flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <div className=" flex-col self-stretch my-auto text-lg font-bold max-md:mt-10 max-md:max-w-full">
            <h2 className="text-5xl text-black max-md:max-w-full max-md:text-4xl">
              Unlock Your Supply Chain's Potential
            </h2>
            <p className="mt-14 leading-8 text-neutral-700 max-md:mt-10 max-md:max-w-full">
              Our solutions are tailored to meet your specific needs, providing
              practical, value-driven tools that enhance your supply chain
              performance. With over 280 advanced algorithms and machine
              learning technology, our software ensures accurate forecasts and
              efficient planning.
            </p>
            <CallToAction link={"https://meetings.hubspot.com/jake-daly/demo"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlockPotential;
