import React from "react";
import Logo from "./Logo";
import CallToAction from "components/home/CallToAction";

const Header = () => {
  return (
    <header className="flex overflow-hidden relative flex-col items-end lg:px-16 pt-8 pb-20 w-full text-lg font-bold min-h-[891px] max-md:max-w-full">
      <a href="/avercast" className="mx-auto w-fit lg:absolute lg:left-[50%] lg:-translate-x-[50%] top-10 z-50">
        <Logo />
      </a>
      <img
        loading="lazy"
        src={require("assets/avercast/hero-bg.png")}
        alt=""
        className="object-cover z-40  lg:absolute inset-0 size-full"
      />
      <div className="flex z-40 flex-col lg:mr-24 mb-36 max-w-full w-[674px] md:w-full max-md:mb-10 lg:relative lg:-right-[35%]">
        <div className="w-full h-16 hidden lg:block"></div>
        <div className="flex flex-col self-end mt-36 max-w-[700px] max-md:mt-10 mx-auto px-[10%] lg:px-0 text-center lg:text-left">
          <h1 className="text-5xl text-black max-md:max-w-full max-md:text-4xl">
            Comprehensive Supply Chain Planning and Business Intelligence
          </h1>
          <p className="mt-12 leading-8 text-neutral-700 max-md:mt-10 max-md:max-w-full">
            Streamline operations and drive growth with our advanced supply
            planning solutions.
          </p>
          <CallToAction link={"https://meetings.hubspot.com/jake-daly/demo"} />
        </div>
      </div>
    </header>
  );
};

export default Header;
