
import React from "react";

const Logo = () => {
  return (
    <img
      loading="lazy"
      src={require("assets/avercast-logo.png")}
      alt="Company Logo"
      className="max-w-full aspect-[3.33] w-[221px]"
    />
  );
};

export default Logo;
